<template>
  <modal name="editTermoModal" height="auto" width="80%" :scrollable="true" :clickToClose="false">
      <CloseModal :close="close" />
      <div class="modalbox">
        <div class="modalbox-content">
          <div v-if="message" class="bdti-alert bdti-alert-success"> {{ message }}</div>
          <div v-if="error" class="bdti-alert bdti-alert-danger"> {{ error }}</div>
          <div class="form">
            <div class="form-single">
              <div class="xrow">
                <div class="col">
                  <div class="form-group">
                    <div class="xrow">
                      <div class="col-1-1"> 
                        <label for="tituloUso"> Titulo </label>
                        <input id="tituloUso" type="text" class="form-control" v-model="termo.titulo">
                        <br />
                        <ckeditor type="classic" v-model="termo.conteudo" :config="editorConfig" :editor="editor"></ckeditor>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer right">
        <div v-if="modoDeEdicao !== 'criar'" style="margin-right: .2em;">
          <input id="novoVigor" type="checkbox" class="checkbox" v-model="novoVigor">
          <label for="novoVigor"> Solicitar nova aprovação dos clientes </label>
        </div>
        <button class="button button-success" v-on:click="handleSubmit">
          Salvar
        </button>
        <button class="button" v-on:click="close">Fechar</button>
      </div>
    </modal>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br.js";
import CloseModal from "../../components/CloseModal";

export default {
  name: "editTermo",
  components: { CloseModal },
  props: ["modoDeEdicao", "currentTermo", "acao", "close", "message", "error"],
  data() {
    return {
      novoVigor: false,
      editor: ClassicEditor,
      editorConfig: {
        language: "pt-br",
        toolbar: ["heading", "bold", "italic", "link"],
      },
    };
  },
  methods: {
    handleSubmit() {
      this.acao({ ...this.termo, vigor: this.novoVigor });
    },
  },
  computed: {
    termo() {
      return this.currentTermo;
    },
  },
};
</script>
