<template>
  <div>
    <Loading :isActive="loading" />
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab
        title="Avançado"
        v-if="
          loadAuthorizationAction(
            'ADMIN_CONFIGURACAO',
            'CONFIGURACOES_AVANCADO'
          )
        "
      >
        <h2>As configurações aqui existentes foram mescladas com a aba de parâmetros</h2>
        <router-link to="/configuracoes/parametros">Clique aqui para acessar</router-link>
      </CTab>
      <CTab
        title="Integrações"
        v-if="
          loadAuthorizationAction(
            'ADMIN_CONFIGURACAO',
            'CONFIGURACOES_INTEGRACOES'
          )
        "
      >
        <Integracoes />
      </CTab>
      <CTab
        title="Traduções"
        v-if="
          loadAuthorizationAction(
            'ADMIN_CONFIGURACAO',
            'CONFIGURACOES_TRADUCOES'
          )
        "
      >
        <Traducoes />
      </CTab>
      <CTab
        title="Termos"
        v-if="
          loadAuthorizationAction('ADMIN_CONFIGURACAO', 'CONFIGURACOES_TERMOS')
        "
      >
        <Termos
          :termosData="termosData"
          :status="termoStatus"
          :clearStatus="clearTermoStatus"
          :criarTermo="criarTermo"
          :salvarTermo="salvarTermo"
          :deletarTermo="deletarTermo"
        />
      </CTab>
      <CTab
        title="Logos"
        v-if="
          loadAuthorizationAction('ADMIN_CONFIGURACAO', 'CONFIGURACOES_LOGOS')
        "
      >
        <Logos
          :logoUrl="images.logo"
          :faviconUrl="images.favicon"
          :upload="handleUpload"
        />
      </CTab>
      <CTab
        title="Imagens de Fundo"
        v-if="
          loadAuthorizationAction(
            'ADMIN_CONFIGURACAO',
            'CONFIGURACOES_IMAGENS_FUNDO'
          )
        "
      >
        <Backgrounds
          :baseUrl="images.background"
          :sistemas="sistemas"
          :hash="makeHash"
          :upload="handleUpload"
        />
      </CTab>
      <CTab
        title="Temas"
        v-if="
          loadAuthorizationAction('ADMIN_CONFIGURACAO', 'CONFIGURACOES_TEMAS')
        "
      >
        <Temas :temas="temas" :salvarTema="salvarTema"></Temas>
      </CTab>
      <CTab
        title="Força de Vendas"
        v-if="
          temSistemaFV &&
          loadAuthorizationAction(
            'ADMIN_CONFIGURACAO',
            'CONFIGURACOES_FORCA_VENDAS'
          )
        "
      >
        <ForcaVendas />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import { upload, get, put, post, del } from "@/helpers/apiRequest";
import { loadAuthorizationAction } from "@/helpers/cargas";
import config from "@/config";
import Logos from "./Logos";
import Backgrounds from "./Backgrounds";
import Integracoes from "./Integracoes";
import Traducoes from "./Traducoes";
import Termos from "./Termos";
import Temas from "./Temas";
import ForcaVendas from "./ForcaVendas";
import _filter from "lodash/filter";
import _find from "lodash/find";
import Toast from "vue-toastification";
import Vue from "vue";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(Toast, {});
const TERMO_STATUS_INICIAL = {
  geral: {
    msg: null,
    error: null,
  },
  edit: {
    msg: null,
    error: null,
  },
};

export default {
  name: "configuracoes",
  components: {
    ForcaVendas,
    Backgrounds,
    Logos,
    Integracoes,
    Traducoes,
    Termos,
    Temas,
    Toast,
  },
  props: ["sistemas"],
  computed: {
    temSistemaB2B() {
      return !!_find(this.sistemas, (sistema) => sistema.chave === "B2B");
    },

    temSistemaFV() {
      return !!_find(
        this.sistemas,
        (sistema) => sistema.chave === "FORCA_VENDAS"
      );
    },
  },
  data() {
    return {
      descPlural: "",
      descSingular: "",
      loading: false,
      temas: [],
      traducoes: [],
      termosData: [],
      termoStatus: TERMO_STATUS_INICIAL,
      images: {
        logo: config.api + "/images/logo?cache=" + this.makeHash(),
        favicon: config.api + "/images/favicon/icon-72x72",
        background: config.api + "/images/background/",
      },
      uploadedImages: {
        logo: config.api + "/images/logo",
        favicon: config.api + "/images/favicon/icon-72x72",
        background: config.api + "/images/background/",
      },
      urls: {
        logo: config.api + "/admin/logo",
        favicon: config.api + "/admin/favicon",
        background: config.api + "/admin/background",
      },
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem("app"));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === "ADMIN_CONFIGURACAO"
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadAuthorizationAction,
    makeHash() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
    handleUpload(type, extraInputs) {
      event.preventDefault();

      const self = this;

      this.loading = true;

      return upload(
        this.urls[type],
        "image",
        event.target.files[0],
        extraInputs
      )
        .then((response) => {
          this.loading = false;

          if (type !== "background") {
            self.images[type] = self.uploadedImages[type] +=
              "?cache=" + self.makeHash();
          }
        })
        .catch((error) => {
          this.loading = false;
          alert(error.message);
        });
    },
    loadTermos() {
      this.loading = true;
      get("/admin/termos")
        .then((json) => (this.termosData = json))
        .finally(() => (this.loading = false));
    },
    criarTermo(data) {
      this.loading = true;
      post("/admin/termos", data)
        .then((res) => this.setTermoStatus("edit", "msg", res.message))
        .catch((err) => this.setTermoStatus("edit", "error", err.message))
        .finally(() => {
          this.loading = false;
          this.loadTermos();
        });
    },
    salvarTermo(termo) {
      this.loading = true;

      const novoTermo = {
        titulo: termo.titulo,
        conteudo: termo.conteudo,
        tipo: termo.tipo,
        vigor: termo.vigor,
      };

      put(`/admin/termos/${termo.id}`, novoTermo)
        .then((res) => this.setTermoStatus("edit", "msg", res.message))
        .catch((err) => this.setTermoStatus("edit", "error", err.message))
        .finally(() => {
          this.loading = false;
          this.loadTermos();
        });
    },
    deletarTermo(termoId) {
      del(`/admin/termos/${termoId}`)
        .then((res) => this.setTermoStatus("geral", "msg", res.message))
        .catch((err) => this.setTermoStatus("geral", "error", err.message))
        .finally(() => {
          this.loading = false;
          this.loadTermos();
        });
    },
    setTermoStatus(key, tipo, data) {
      this.termoStatus = {
        ...TERMO_STATUS_INICIAL,
        [key]: {
          [tipo]: data,
        },
      };
    },
    clearTermoStatus() {
      this.termoStatus = TERMO_STATUS_INICIAL;
    },
    loadTemas() {
      this.loading = true;
      get("/admin/temas")
        .then((json) => (this.temas = json))
        .finally(() => (this.loading = false));
    },
    salvarTema(tema) {
      this.loading = true;

      const request = put(`/admin/temas/${tema.sistema}`, tema.schema);

      request
        .then(() => this.loadTemas())
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));

      return request;
    },
  },
  beforeMount() {
    this.loadTermos();
    this.loadDescricaoModulo();
    this.loadTemas();
  },
};
</script>
