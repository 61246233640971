<template>
  <div>
    <EditTermo
      :modoDeEdicao="modoDeEdicao"
      :currentTermo="currentTermo"
      :acao="modoAcoes[modoDeEdicao]" 
      :close="closeEditTermo"
      :message="status.edit.msg"
      :error="status.edit.error"
    ></EditTermo>

    <div v-if="status.geral.msg" class="bdti-alert bdti-alert-success"> {{ status.geral.msg }}</div>
    <div v-if="status.geral.error" class="bdti-alert bdti-alert-danger"> {{ status.geral.error }}</div>

    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Titulo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> B2B | TERMO DE USO</td>
            <td>{{ termos.USO.titulo }}</td>
            <td class="actions">
              <span v-if="termos.USO.id">
                <CButton color="primary" size="sm" @click="openEditTermo('MODO_EDITAR', 'USO')">
                  <fa-icon icon="pencil-alt" />
                </CButton>
                <CButton color="danger" name="button" @click="deleteConfirm('USO')" size="sm">
                  <fa-icon icon="trash-alt" />
                </CButton>
              </span>
              <CButton v-else color="success" @click="openEditTermo('MODO_CRIAR', 'USO')" size="sm">
                Criar
              </CButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import clone from 'just-clone';
import EditTermo from './EditTermo';

const TERMO_INICIAL = {
  titulo: 'Novo Termo',
  conteudo: '',
};

export default {
  name: 'termos',
  components: { EditTermo },
  props: [
    'termosData',
    'status',
    'clearStatus',
    'criarTermo',
    'salvarTermo',
    'deletarTermo',
  ],
  data() {
    return {
      currentTermo: TERMO_INICIAL,
      modoDeEdicao: '',
      modoAcoes: {
        MODO_CRIAR: this.criarTermo,
        MODO_EDITAR: this.salvarTermo,
        MODO_DELETAR: this.deletarTermo,
      },
    };
  },
  computed: {
    termos() {
      return {
        USO: this.termosData.find((t) => t.tipo === 'USO') || TERMO_INICIAL,
      };
    },
    messageEdit() {
      return this.message;
    },
    errorEdit() {
      return this.error;
    },
  },
  methods: {
    openEditTermo(modoDeEdicao, tipoTermo) {
      this.modoDeEdicao = modoDeEdicao;
      this.currentTermo = {
        ...this.termos[tipoTermo],
        tipo: tipoTermo,
      };

      this.$modal.show('editTermoModal');
    },

    closeEditTermo() {
      this.clearStatus();
      this.$modal.hide('editTermoModal');
    },

    deleteConfirm(tipoTermo) {
      this.$swal({
        title: 'Confirma Remover?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      }).then((res) => {
        if (res.value) {
          this.deletarTermo(this.termos[tipoTermo].id);
        }
      });
    },
  },
};
</script>
